var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('MobileScreen',{attrs:{"loading":_vm.loading,"title":_vm.$vuetify.lang.t('$vuetify.dashboard.sidebar.referral_program'),"icon":"$megaphoneLine"},scopedSlots:_vm._u([(_vm.canInviteReferralsGetter)?{key:"content",fn:function(){return [_c('InfiniteList',{attrs:{"loading":_vm.loading,"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('ExpandCardTile',{attrs:{"headers":_vm.tableHead,"item":item},scopedSlots:_vm._u([{key:"header-title",fn:function({ item }){return [_c('h2',{staticClass:"mb-0 text-caption-2 font-weight-bold"},[_vm._v(" ID: "+_vm._s(item.referralId)+" ")])]}},{key:`item.referralEmail`,fn:function({ item: { referralEmail } }){return [_c('div',{staticClass:"d-flex align-center font-weight-bold text-subtitle-4"},[_c('v-icon',{staticClass:"flex-shrink-0 mr-2",attrs:{"size":"16","color":"grey"}},[_vm._v(" $email ")]),_c('span',{staticClass:"text-truncate flex-shrink-1 flex-grow-1",style:({
                  width: 0,
                })},[_vm._v(" "+_vm._s(referralEmail)+" ")])],1)]}},_vm._l(([
              'referralEnrollAmount',
              'expectedProfitAmount',
              'actualProfitAmount',
            ]),function(itemName){return {key:`item.${itemName}`,fn:function({ item, header }){return [_c('div',{key:itemName,staticClass:"d-flex align-center"},_vm._l((header.children),function({ value, currency },index){return _c('span',{key:value,class:['flex-shrink-0', { 'ml-3': index > 0 }]},[_vm._v(" "+_vm._s(_vm.formatMoney({ value: item[value], currency, }))+" ")])}),0)]}}})],null,true)})]}}],null,false,2843777627)})]},proxy:true}:null],null,true)},[(_vm.canInviteReferralsGetter)?[_c('v-divider'),_c('ReferralProfit',{staticClass:"mt-4"}),_c('ReferralInviteUrl',{staticClass:"my-4"})]:_vm._e(),(!_vm.canInviteReferralsGetter)?_c('LockedPageBanner',{attrs:{"page-name":"referral-program"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }